section.page-header {
    height: 200px;
    background-size: cover;
    position: relative;
    margin-top: 5rem;
    background-position: center;

    @media #{$medium-only} {
        margin-top: 11rem;
    }

    @include with-touch-nav {
        margin-top: 0;
    }

    // BLUE WASH ON IMAGES

    // &:before {
    // 	content:'';
    // 	width: 100%;
    // 	height: 200px;
    // 	position: absolute;
    // 	background-color: rgba(77, 108, 143, 0.6);
    // 	top: 0;
    // 	left: 0;

    // }

    h1 {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 2rem;
        padding-top: 5rem;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
    }
}

main {
    padding-bottom: 4rem;

    .title-box {
        display: inline-block;
        height: 100%;
        width: 100vw;
        background-color: $color-denim;
        text-align: center;
        margin-bottom: 50px;

        h1 {
            margin-top: 10px;
            padding-bottom: 1rem;
            font-weight: 500;
            font-size: 3rem;
            color: #fff;

            @media #{$small-only} {
                padding: 0 1rem 1rem 1rem;
                font-size: 2rem;
            }
        }

        .wysiwyg {
        }
    }
}

main.single {
    padding-top: 2rem;
    background-color: #fff;
}
