div.ui-tooltip {
	border: solid 1px #000;
	color: #000;
}

table.simcal-calendar-grid {
	margin-bottom: 2rem;
	
	tr {
		
		td {
			
		}
	}
}

ul.simcal-events {
	li {
		&::before {
			display: none;
		}
	}
}
/* stylelint-disable */
.simcal-tooltip-content {
	// display: inline-block !important;

	strong {
		font-weight: bold;
	}
}

.ui-tooltip {
	border: 1px solid #0c2e3a !important;
}
/* stylelint-enable */

