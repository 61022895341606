.home {
    .posts {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        gap: 2rem;

        article {
            max-width: 450px;
            background-color: #fff;
            color: #0c1728;

            figure {
                width: 100%;
                height: 100%;
                max-height: 250px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 250px;
                    object-fit: cover;
                    object-position: center;
                }
            }

            section {
                padding: 1rem;

                h1,
                h1 a {
                    color: #446d92;
                    width: 100%;
                    text-align: center;
                    font-size: 2rem;
                    margin: 0 0 0.75rem;
                }

                p {
                    line-height: 1.25;
                }
            }
        }
    }

    .pagination-links {
        padding: 3rem 0;
        display: flex;
        justify-content: center;
        gap: 0.5rem;

        .current {
            color: #ffbe00;
        }
    }
}
