section.testimonials {
	position: relative;
	height: 300px;
	background-color: $color-stone;
	background-image: url(../images/fa-quote-left.svg);
	background-position: center 5rem;
	background-size: 7rem auto;
	background-repeat: no-repeat;

	@media #{$medium-only} {
		height: 450px;
		background-image: none;
	}

	@media #{$small-only} {
		height: 550px;
		background-image: none;
	}

	.testimonial-padding {
		padding: 0 2rem;
	}

	.photo {
		
		margin: 0 auto;
		margin-top: -3rem;
		width: 6rem;
		height: 6rem;
		border-radius: 50%;
		border: solid 3px $color-stone;
		background-size: cover; 
		z-index: 50;
		transition: opacity 0s ease-in-out;
	}

	blockquote {
		padding-top: 3rem;
		font-size: 1.5rem;
		line-height: 2.6rem;
		color: #0c2e3a;
		display: inline-block;

		@media #{$medium-only} {
			padding-top: 0;
		}
		@media #{$small-only} {
			padding-top: 0;
			line-height: 2rem;
			font-size: 1.2rem;
		}
		
		p {
			display: inline;
			margin: 0;
			font-style: italic;
			
			&::before {
				content: "“";
			}
			
			&::after {
				content: "”";
			}
		}
		
		cite {
			display: inline-block;
			font-size: 1rem;
			width: 100%;
			line-height: 1.5rem;
			padding-left: 1rem;
			text-indent: -1rem;
			margin-left: 3rem;
			font-weight: normal;

			@media #{$small-only} {
				margin-left: 0;
			}
			
			&::before {
				content: "— ";
			}
		}
	}

	.testimonial-image {
		padding: 3.5rem 0;
		text-align: center;
	}
}

body > footer {
	padding-top: 2.25rem;
	padding-bottom: 2.25rem;
	font-size: 1.125rem;
	text-align: center;
	background-color: $color-midnight;
	position: relative;
	z-index: 10;

	a {
		color: $color-sunrise;
	}
}