body.page-template-page-careers {

	main {
		padding: 0;
		margin-top: 5rem;

		@media #{$medium-only} {
			margin-top: 11rem;
		}

		@media #{$small-only} {
			margin-top: 0;
		}
	}

	.city-bg {
		background-image: url('../images/cityscape-bg.png');
		background-size: cover;
		height: 790px;
		position: relative;
		z-index: 0;

	}

	.career-label-wrap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 2rem;

		.careers-sidebar {
			width: 260px;
			display: inline-block;
			max-height: 320px;
			text-align: right; 
			font-size: 1.1rem;
			line-height: 1.8rem;
			max-width: 270px;
			padding: 2rem;
			position: relative;
			margin: 0; 
			background-color: rgba(26, 59, 74, 0.9);
			z-index: 40;

			@media #{$small-only} {
				height: auto;
			}

			a {
				color: $color-sunrise;
				margin-top: 0.5rem;
				display: inline-block;

				@media #{$small-only} {
					display: none;
				}
			}

			&:after {
				content: '\f105';
				font-family: fontawesome; 
				font-size: 4rem;
				color: $color-sunrise;
				position: absolute;
				right: -10px;
				top: 40%;

				@media #{$small-only} {
					display: none;
				}
			}

			@media #{$small-only} {
				width: 100%;
				max-width: 100%;
				text-align: center;
			}
		}


		.fallback-nav {

			padding: 2rem;
			display: flex;
			z-index: 20;

			@media #{$small-only} {
				padding: 2rem 0;
				width: 100%;
			}

			i {
				font-size: 2rem;
				cursor: pointer;

				@media #{$small-only} {
					display: none;
				}
			}

			ul.career-nav {
				height: 0px;
				width: 300px;
				overflow: hidden;
				margin-right: 1rem;
				background-color: rgba(26, 59, 74, 0.9);
				transition: all 0.7s ease-in-out;

				@media #{$small-only} {
					height: auto;
					padding-bottom: 1rem;
					width: 100%;
					margin-right: 0;
					border-left: 5px solid $color-sunrise;
				}

				&.active {
					height: 400px;
				}

				li {
					padding: 0.5rem 1rem;

					@media #{$small-only} {
						padding: 0.5rem 2rem;
					}

					&:first-child {
						margin-top: 1rem;
					}

					a {
						&:hover {
							color: $color-sunrise;

							&:after {
								content: '\f105';
								font-family: fontawesome; 
								font-size: 1rem;
								color: $color-sunrise;
								margin-left: 0.5rem;

							}
						}
					}
				}
			}


		}
	}

	.building-menu {
		width: 100%;
		display: flex;
		transition: all 1s ease-in-out;
		position: absolute;
		bottom: 0;

		@media #{$small-only} {
			display: none;
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		    &:after{
		    	content: '';
		    	width: 100%;
		    	height: 60px;
		    	background-color: #fff;
		    	position: absolute;
		    	bottom:0;
		    	left: 0;
		    	z-index: 40;
		    }
		}

		a.building-nav-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 600px;
			width: 10%;
			flex-shrink: 0;

			&:hover {
				.link-title {
					opacity: 100;
				}

				svg {
					fill: $color-sunrise;
				}
			} 

			.link-title {
				text-align: center;
				position: relative;
				opacity: 0;
				transition: all 0.5s ease-in-out;
				background-color: rgba(26, 59, 74, 0.9);
				padding: 10px 3px;
				margin-top: 180px;
				width: 100%;
				display: inline-block;


				&:after {
					content: '\f107';
					font-family: fontawesome; 
					font-size: 1.5rem;
					color: $color-sunrise;
					left: 50%;
					top: 55px;
					position: absolute;

				}
			}



			svg {
				fill: #fff;
				transition: all 0.7as ease-in-out;
				margin-bottom: -2px;
				width: 100%;
			} 
			
		} 
	}

	section.testimonials {
		display: none;
	}
}

a.back-to-careers {
	text-decoration: none;
	font-weight: bold;
	font-size: 1.5rem;
	margin-bottom: 3rem;
	display: inline-block;
	padding-left: 0.5rem;
	transition: all ease 0.5s;

	&::before {
		content: '\f100';
		font-family: fontawesome;
		color: $color-sunrise;
		margin-right: 1rem;
		transition: all ease 0.5s;
	}

	&:hover {
		padding-left: 0;
	&::before {
		margin-right: 1.5rem;
	}
	}
}