

ul.two-columns {
  columns: 2;
  float: left;
  position: relative;
  margin-right: 20px;
  margin-left: 0;

  @media #{$small-only} {
	columns: 1;
  }

  li {
  	padding-bottom: 0.5rem;

  	&:before {
  		display: none;
  	}
  }

  li.title {
  	padding: 1.5rem 0 0.5rem 0;
  	font-weight: bold;
  	color: $color-sunrise;

  	&:first-child {
  		padding-top: 0;
  	}
  }
}