section.mission {

		
		background-size: cover; 
		padding: 2rem 0px;
		position: relative; 



		h2 {
			font-size: 3rem;
			text-align: center;
			margin-bottom: 1.5rem;
		}

		p {
			text-align: center;
			line-height: 1.2rem;
		}

		.mission-row {
			display: flex;
			flex-wrap: wrap;

			.mission-column {
				width: 33%;
				text-align: center;
				padding: 2rem 1rem;

				&:hover i {
					color: $color-sunrise;
				}

				@media #{$medium-only} { 
					width: 100%;
				}

				@media #{$small-only} { 
					width: 100%;
				}

				i {	
					display: block;
					margin: 0 auto 1.125rem auto;
					padding: 1.8rem;
					width: 5.5rem;
					height: 5.5rem;
					font-family: fontawesome;
					font-size: 2rem;
					background-color: #56779A;
					color: #fff;
					border: none;
					border-radius: 50%;
				}

				h3 {
					font-size: 2rem;
					margin-bottom: 1rem;
				}

				p {
					line-height: 28px;
					color: $color-sunrise;
					font-size: 18px;
				}

				a.fancylink {
					margin-top: 2rem;					
				}
			}

			.mission-links {
				width: 33%;
				text-align: center;
				padding-bottom: 2rem;

				@media #{$medium-only} { 
					display: none;
				}
				@media #{$small-only} { 
					display: none;
				}
			}
		}
	}