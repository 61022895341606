.contact-wrapper {
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	background-color: #fff;

	section.page-header {
		
		&::before {
			display: none;
		}

	}

	main.contact {
		padding-top: 0;
		padding-bottom: 9rem;
		background-color: transparent;
		
		.wysiwyg {
			
			.title-box {
				display: inline-block;
				height: 3.75rem;
				width: 100vw;
				background-color: $color-denim;

				h1 {
					padding-top: 0.3rem;
					color: #fff;
				}
			}

			.gforms_confirmation_message {
				color: #fff;
				background-color: #4D6C8F;
				margin-top: 3rem;
				padding: 2rem 4rem;
				font-size: 1.5rem;
				line-height: 1.6rem;
			}
			
			.gform_wrapper {

				background-color: #4D6C8F;
				margin-top: 3rem;
				padding: 2rem 4rem;

				@media #{$small-only} {
					padding: 2rem;
				}
				
				form {
					
					.gform_body {

						
						select.medium {
							width: 110%;
							position: relative;
							background-color: transparent;
							font-weight: lighter;
							font-size: 1.2rem;

							/* stylelint-disable */
							appearance: none !important;
							/* stylelint-enable */
							
							border: none;
							padding: 0.5rem 5rem 0.5rem 1rem;
							z-index: 15;

							option {
								background-color: #EDF4FE;
								padding: 0.3rem;
							}

							&::-ms-expand {
							    display: none;
							}
						}

						@-moz-document url-prefix() { 
						  select.medium {
						     padding: 0.25rem 1rem;
						  }
						}
						



						.ginput_container_select {

							overflow: hidden;

							&::after {
								content: '';
								width: 30px;
								height: 60px;
								background-image: url('../images/arrows.png');
								background-repeat: no-repeat;
								background-size: 15px;
								position: absolute;
								right: 0px;
								top: 6px;
								z-index: 10;
							}

							&::before {
								content: '';
								background-color: #EDF4FE;
								width: 100%;
								height: 3rem;
								position: absolute;
								left: 0;
								top: -2px;
								border: 1px solid $color-midnight;
								z-index: 5;
							}
						}						
					}
						
						.gform_fields {

							position: relative;
							
							.gf-left {
								display: inline-block;
								float: left;
								clear: left;
								width: 48%;
								font-size: 1rem;

								@media #{$small-only} {
									width: 100%;
								}

								input {
									height: 3rem;
									border-radius: 0px;
								}
							}

							.gf-right {
								display: inline-block;
								width: 48%;
								position: absolute;
								top: 0;
								right: 0;

								@media #{$small-only} {
									width: 100%;
									position: relative;
								}

							}

							li {
								label {
									display: none;
								}

								input {
									padding: 0.5rem 1rem;
									
								}
							}

							textarea {
								height: 12.5rem;
							}
						}
					
					
					.gform_footer {
						
						button {
							
						}
					}
				}
			}
			

			.contact-info {

				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.phone,
				.address {
					position: relative;
					margin: 0rem;
					font-size: 1.8rem;
					line-height: 2.1rem;
					color: #446d92;
					padding-top: 4rem;
					margin-top: 2rem;
					flex-basis: 20rem;
					flex-grow: 1;
					text-align: center;
					font-weight: lighter;

					
					&::before {
						position: absolute;
						top: 0;
						font-size: 3rem;
						left: calc(50% - 1rem);
						font-family: fontawesome;
						color: $color-sunrise;
					}
					
					a {
						color: #446d92 ;
						font-weight: lighter;
						text-decoration: none;
					}
				}
				
				.phone {

					color: #446d92 ;
					
					&::before {
						content: "\f095";
					}
				}
				
				.address {
					
					&::before {
						content: "\f041";
					}
					
				}
			}
		}
	}
}