// Add this class or pseudoclass to any content generated by WordPress.
// Additionally, it can be used when you want to ensure consistent styling.

// Edit + remove styles here freely.

main.page,
.sidebar {
    padding-top: 0rem;
    padding-bottom: 3rem;
    background-color: #fff;
    position: relative;
}

%wysiwyg,
.wysiwyg,
.widget {
    color: $color-midnight;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 1.625rem 0;
        text-align: center;
        line-height: 3rem;
        font-weight: 300;
    }

    h1 {
        font-size: 3rem;
        font-weight: lighter;
    }

    h2 {
        font-size: 2.25rem;
        font-weight: normal;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    h3 {
        font-size: 2rem;
        font-weight: normal;
    }

    h4 {
        font-size: 1.75rem;
    }

    h5 {
        font-size: 1.5rem;
    }

    h6 {
        font-size: 1.313rem;
    }

    p {
        margin-bottom: 1.625rem;
        font-size: 1.2rem;
        color: $color-denim;
        line-height: 2rem;
        font-weight: lighter;
    }

    ul,
    ol {
        margin-bottom: 1.625rem;
        margin-left: 1.8rem;

        li {
            position: relative;
            margin-bottom: 0.875rem;
            line-height: 1.7rem;
            font-weight: lighter;
            color: $color-denim;

            &::before {
                position: absolute;
                top: 0;
                left: -1.8rem;
                color: $color-sunrise;
            }
        }
    }

    ul {
        list-style-type: none;

        li {
            font-size: 1.2rem;
            line-height: 1.7rem;
            font-weight: lighter;
            margin-bottom: 0;
            color: $color-denim;

            &::before {
                content: "\f101";
                font-family: fontawesome;
                font-size: 1.5rem;
            }
        }
    }

    ol {
        counter-reset: ol;

        > li {
            counter-increment: ol;

            &::before {
                content: counter(ol);
            }
        }
    }

    a {
        text-decoration: underline;
        color: $color-denim;

        &:hover {
            text-decoration: none;
        }

        &.fancylink {
            span {
                transition: all 1s ease;
            }

            span:hover {
                transform: translate(15px);
            }
        }
    }

    button,
    a.button,
    input[type="button"] {
        display: inline-block;
        padding: 0.2rem;
        border-style: none;
        outline: none;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    textarea,
    select {
        padding: 1rem 1.25rem;
        background-color: $color-babybreath;
        border: 1px solid $color-midnight;
        font-size: 1.3125rem;
        color: #446d93;
        outline: none;
        /* stylelint-disable */
        border-radius: 0px !important;
        /* stylelint-enable */

        &::placeholder {
            color: #d6d7d5;
        }

        &:focus {
            background-color: #fff;
        }
    }

    .gform_wrapper {
        margin: 0;
        padding: 0;
        max-width: 100%;

        form {
            .validation_error {
                text-align: center;
            }

            .gform_body {
                ul.gform_fields {
                    li.gfield {
                        margin: 0 0 1.75rem 0;
                        border-style: none;
                        padding-right: 0;

                        &.gf_left_half {
                            margin-right: 1.75rem;
                        }

                        &.gf_left_half,
                        &.gf_right_half {
                            width: 47%;
                        }

                        span.name_first {
                            padding: 0;
                        }

                        .gfield_label {
                            margin: 0;

                            .gfield_required {
                            }
                        }

                        .ginput_container {
                            margin: 0;

                            input[type="text"],
                            input[type="email"],
                            input[type="number"],
                            input[type="tel"],
                            input[type="password"],
                            textarea {
                                /* stylelint-disable */
                                padding: 0.6rem 1rem !important;
                                /* stylelint-enable */
                                width: 100%;
                                font-size: 1.3125rem;
                                font-weight: lighter;
                                background-color: #edf4fe;
                                border: 1px solid #0c1728;

                                &::placeholder {
                                    color: $color-denim;
                                }

                                &:focus {
                                    background-color: #fff;
                                }
                            }

                            .name_first {
                                margin-right: 1.75rem;
                            }

                            .name_first,
                            .name_last {
                                padding: 0;
                                width: 47%;
                            }
                        }

                        &.gfield_error {
                            margin: 0;
                            width: 100%;

                            .ginput_container {
                                max-width: 100%;
                                margin: 0;

                                input[type="text"],
                                input[type="email"],
                                textarea {
                                    margin: 0;
                                }
                            }

                            .validation_message {
                                margin: 0;
                                padding: 0;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }

            .gform_footer {
                text-align: right;

                button {
                    margin-right: 1.5rem;
                    /* stylelint-disable */
                    border-style: none !important;
                    /* stylelint-enable */
                }
            }
        }
    }

    #gform_wrapper_4 {
        margin: 1.5rem 0;

        form {
            display: flex;
            flex-wrap: wrap;

            .gform_heading {
                width: 100%;

                .gform_title {
                    margin: 2rem 0 0 0;
                    text-align: center;
                    line-height: 3rem;
                    font-size: 2.25rem;
                    font-weight: normal;
                }
            }

            .gform_body {
                display: flex;
                flex-grow: 1;
                flex-basis: 30rem;
                margin: 0 2rem 0 0;
                width: auto;

                @media (max-width: 45rem) {
                    margin: 0 0 0 1rem;
                    justify-content: center;
                }

                .gform_fields {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;

                    .gfield {
                        display: flex;
                        flex-grow: 1;
                        flex-direction: column;
                        flex-basis: 10rem;
                        margin: 0;

                        &:not(:last-of-type) {
                            margin-right: 2rem;
                        }

                        @media (max-width: 45rem) {
                            margin-left: 1rem;
                            margin-right: 1rem;
                        }

                        .gfield_label {
                            display: none;

                            .gfield_required {
                            }
                        }

                        .ginput_container {
                            width: 100%;

                            span {
                                margin: 0;

                                input {
                                    display: block;
                                    width: 100%;
                                }

                                label {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .gform_footer {
                flex-shrink: 0;
                margin: 0;
                padding: 2rem 0;
                text-align: center;

                @media (max-width: 45rem) {
                    display: flex;
                    width: 100%;
                    padding-top: 1rem;
                    justify-content: center;
                }
            }
        }
    }

    blockquote {
        margin-bottom: 1.625rem;

        cite {
        }
    }

    strong {
        font-weight: bold;
    }

    .aligncenter {
        text-align: center;
    }

    .alignright {
        float: right;
        margin: 0px 0px 20px 20px;
    }

    .alignleft {
        float: left;
        margin: 0px 20px 20px 0px;
    }
}
