.slideshow {
	position: relative;
	display: block;
	width: 100%;

	& > .slides {
		display: block;
		height: 100%;
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;

		.slide {
			transition: none;
			position: absolute;
			top: 0;
			opacity: 0;
			display: block;
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

			&.next {
				opacity: 0;

				.photo {
					display: none;
				}
			}

			&.previous {
				opacity: 0;

				.photo {
					  display: none;

				}
			}

			&.current {
				opacity: 1;
				transition: none;

				.photo {
					opacity: 100;
				}

			}

			.container {
				width: 100%;
				max-width: $row-width;
				text-align: center;
			}
		}
	}

	& > .controls {
		position: absolute;
		top: 30%;
		width: 100%;
		padding: 2rem;
		transition: all 1s ease-in-out;		

		&.in-view {
			width: 100%;
			margin-left: 0;
		}

		i {
			color: #0c2e3a;
			font-size: 50px; 
			width: 30px;
		}

		.previous {
			cursor: pointer;
			position: absolute;
			left: 1rem;
		}

		.next {
			cursor: pointer;
			position: absolute;
			right: 0;
		}
	}
}