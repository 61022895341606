// Put SCSS for the front page here
main.front {
}
// stuff and things!
section.splash {
    padding: 5rem 0;
    text-align: center;
    background-color: #1e5776;
    background-size: cover;
    background-position: center top;
    margin-top: 5rem;
    position: relative;

    @media #{$medium-only} {
        margin-top: 11rem;
    }

    @include with-touch-nav {
        margin-top: 0;
    }

    &::after {
        content: "";
        position: absolute;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 21%,
            #0c1728 86%,
            #0c1728 100%
        );
        opacity: 0.6;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: inline-block;
    }

    & > .row {
        position: relative;
        z-index: 1;
    }
    h1 {
        margin: 1rem auto 3.75rem;
        font-weight: 700;
        font-size: 2rem;
        text-transform: uppercase;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #0c3243;
        max-width: 900px;
        padding: 1rem;
        letter-spacing: 2px;

        span {
            margin: 0 0.25rem;

            &.blue {
                color: #3e5f86;
            }
            &.yellow {
                color: #fab730;
            }
        }
    }

    .content {
        max-width: 728px;
        padding: 2rem;
        margin: 0 auto 2rem;
        line-height: 1.2;

        h4 {
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

section.intro {
    padding-top: 0rem;
    padding-bottom: 6rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: center;
    position: relative;
    background-color: #234150;

    /* &::before {
        content: "";
        height: 180px;
        width: 100%;
        background-color: #234150;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @media #{$medium-only} {
            height: 120px;
        }

        @media #{$small-only} {
            height: 160px;
        }
    } */

    h1 {
        color: $color-sunrise;
        font-weight: lighter;
        font-size: 2.5rem;
        line-height: 3rem;
        margin-bottom: 3rem;
        z-index: 20;
        position: relative;

        a::before {
            content: "\f002";
            font-family: fontawesome;
            color: #56779a;
            margin-right: 1rem;
        }

        strong {
            font-weight: bold;
        }
    }

    h2 {
        margin-bottom: 1.5rem;
        font-size: 2.25rem;

        @media #{$small-only} {
            padding-top: 3rem;
        }

        @media #{$medium-only} {
            padding-top: 3rem;
        }

        a {
            color: inherit;
            text-decoration: none;

            &::before {
                display: block;
                margin: 0 auto 1.125rem auto;
                padding: 1.8rem;
                width: 5.5rem;
                height: 5.5rem;
                font-family: fontawesome;
                font-size: 1.5rem;
                background-color: #56779a;
                color: #fff;
                border: none;
                border-radius: 50%;
            }

            &:hover {
                &::before {
                    color: $color-sunrise;
                }
            }
        }
    }

    a {
        display: block;
        color: #ffbe00;
    }

    .alliance {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 4.75rem;
        z-index: 3;

        label {
            flex-shrink: 0;
            font-size: 1.125rem;
            font-weight: 700;

            @media #{$small-only} {
                width: 100%;
                font-size: 2rem;
            }
        }

        .alliance-image {
            @media #{$small-only} {
                width: 100%;
                margin: 1rem 0;
            }
        }
    }
}

section.blog {
    margin: 2rem 0 5rem;

    h2 {
        margin: 1rem auto;
        padding-bottom: 0.5rem;
        font-weight: 700;
        font-size: 1.75rem;
        text-transform: uppercase;
        border-bottom: 2px solid #fab730;
    }

    .featured {
        a {
            display: flex;
            align-items: center;
            gap: 1rem;

            figure {
                max-width: calc(100% / 3);
            }

            section {
                flex-basis: calc(200% / 3);
                flex-grow: 1;

                h3 {
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 0.5rem;
                }

                .excerpt {
                    line-height: 1.5;
                }
            }
        }
    }

    .recent-posts {
        margin: 3.5rem 0.5rem 0;
        background-color: #d6d7d5;
        padding: 2rem;

        h3 {
            font-size: 1.25rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 1.5rem;
            color: #3e5f86;
        }

        h4 {
            font-size: 1.125rem;
            color: #3e5f86;
            margin-bottom: 0.33rem;
        }

        p {
            color: #0c1728;
            font-weight: 700;
            font-size: 0.75rem;
            margin-bottom: 1rem;
        }

        > a {
            display: block;
            width: 100%;
            color: #3e5f86;
            font-weight: 700;
            text-decoration: none;
            text-align: right;
        }
    }
}
