main.error-404 {
	padding: 5rem 0; 

	h1 {
		font-size: 2rem;
		margin-bottom: 1rem;

	}

	p {
		margin-bottom: 1rem;
	}

	form.searchform {
		input {
			background-color: #fff;
			padding: 5px;
		}
	}
}