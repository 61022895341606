@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css);
@import url(//fonts.googleapis.com/css?family=Lato:700,300,300italic,700italic,400italic,400);

// Foundation
@import "normalize" !not-sassy;
@import "lib/foundation" !not-sassy;

// Resets
@import "lib/reset";

// Components, built-in, then custom
@import "components/**/*.scss";

// Constants and colors
@import "constants";
@import "colors";

// Baseline styling
@import "base";

// Content areas
@import "sections/**/*.scss";

// Pages
@import "pages/**/*.scss";