body.page-template-page-about-php {
	
	.page {
		padding-bottom: 2rem;
	}

	.content-centered {
		p {
			text-align: center;
		}
	}

	.second-content {
		
		p {
			text-align: center;
		}

		.c-button {
			text-align: center;
			padding-bottom: 4rem;
		}

		.button-center {
			text-align: center;
			padding-bottom: 2rem; 

			@media #{$small-only} {
				display: none;
			}

			@media #{$medium-only} {
				display: none;
			}
		}

	}

	.button-links {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding: 0; 

		a {
			margin: 1rem 2rem;
		}

	}

	section.bottom-section {

		padding: 2rem 0 8rem 0;
		background-color: $color-midnight;

		h2 {
			font-size: 2rem;
			margin-bottom: 0;
		}

		p {
			margin-bottom: 1rem;
		}

		ul {
			padding-bottom: 2rem;
			padding-top: 1rem;

			li {

				position: relative;
				padding: 0.3rem 0 0.3rem 2rem;
				font-size: 1.2rem;
				line-height: 1.7rem;
				font-weight: lighter;

				&:before {
					content: '\f101' ;
					font-family: fontawesome;
					color: $color-sunrise;
					font-size: 2rem;
					height: 1rem;
					position: absolute;
					top: 0;
					left: 0;
					line-height: 2.4rem;
				}
			}
		}

		.center {
			text-align: center;
		}

		a.fancylink {
			margin-left: 2rem;
		
		}

	}
}