/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

html,
body {
    // overflow-x: hidden;
}

body {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    // font-weight: 400;
    // font-weight: 700;
    color: #fff;
    background-color: #0c2e3a;
}

a {
    transition: all 0.2s ease-in-out;
}

.fancylink,
.wysiwyg .fancylink,
.gform_button {
    position: relative;
    display: inline-flex;
    padding: 1.625rem 2.44rem;
    font-size: 1.125rem;
    font-weight: 700;
    font-style: italic;
    line-height: 1.125rem;
    color: #0c2e3a;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $color-sunrise;
    transition: all 0.2s ease-in-out;

    span {
        transition: all 1s ease;
    }

    &:hover {
        span {
            transform: translate(15px);
        }
    }

    .polygon {
        fill: $color-sunrise;
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        background-color: $color-sunset;

        .polygon {
            fill: $color-sunset;
        }
    }

    .button-left {
        position: absolute;
        top: 0;
        left: -29px;
        width: 35px;
        height: 100%;
    }

    .button-right {
        position: absolute;
        top: 0;
        right: -30px;
        width: 36px;
        height: 101%;
    }
}

.small-only {
    display: none;

    @media #{$small-only} {
        display: block;
    }
}
.medium-only {
    display: none;

    @media #{$small-only} {
        display: block;
    }

    @media #{$medium-only} {
        display: block;
    }
}

.link-center {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}

iframe {
    width: 100%;
    height: 452px;
}

ul.children {
    margin-top: 1rem;
}
h2.wsp-pages-title {
    display: none;
}
