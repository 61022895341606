body > header.desktop {
    background-color: #fff;
    height: 80px;
    overflow: display;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 2px 0px 0px #ccc;

    @media #{$medium-only} {
        height: auto;
        box-shadow: none;
    }

    @include with-touch-nav {
        display: none;
    }

    .logo-area {
        background-color: #fff;
        padding: 20px 0px 20px 0px;
        position: relative;
        width: 260px;
        float: left;
        z-index: 20;
        box-shadow: 0px 2px 0px 0px #ccc;

        @media #{$medium-only} {
            width: 100%;
            padding: 1rem 0 0 0;
            text-align: center;
            box-shadow: none;
        }

        &:before {
            content: "";
            position: absolute;
            left: -100vw;
            width: 100vw;
            height: 108px;
            background-color: #fff;
            box-shadow: 0px 2px 0px 0px #ccc;

            @media #{$medium-only} {
                display: none;
            }
        }

        img {
            width: 260px;
            height: auto;
            z-index: 20;
        }

        svg#logo-triangle {
            position: absolute;
            right: -53px;
            bottom: -0.9px;
            filter: drop-shadow(2px 1px 0px #ccc);
            z-index: 1;

            @media #{$medium-only} {
                display: none;
            }
        }
    }

    nav {
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;

        @media #{$medium-only} {
            padding: 0px;
            width: 100%;
            justify-content: space-around;
        }

        .social-links {
            ul {
                display: flex;
                margin-left: 2rem;
                li {
                    padding: 2rem 0.5rem;
                    a {
                        color: $color-denim;
                        font-size: 1.2rem;

                        &:hover {
                            color: $color-midnight;
                        }
                    }
                }
            }
        }

        ul.menu {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            @media #{$medium-only} {
                width: 100%;
                background-color: #fff;
                justify-content: center;
            }

            li {
                padding: 1.5rem 0 3rem 0;

                @media #{$medium-only} {
                    padding-bottom: 1.5rem;
                }

                &.about a:before {
                    content: "\f05a";
                }

                &.students a:before {
                    content: "\f19d";
                }

                &.employers a:before {
                    content: "\f234";
                }

                &.events a:before,
                &.calendar a:before {
                    content: "\f073";
                }

                &.gala a:before {
                    content: "\f145";
                }

                &.contact a:before {
                    content: "\f0e0";
                }

                &.home a:before {
                    content: "\f015";
                }

                &.media a:before {
                    content: "\f03d";
                }

                > a {
                    position: relative;
                    display: block;
                    margin-left: 1.25rem;
                    font-size: 1rem;
                    font-weight: normal;
                    color: #0c1728;
                    text-indent: -22px;
                    padding-top: 1.3rem;
                    padding-left: 22px;

                    &::before {
                        // margin-right: 0.375rem;
                        // display: block;
                        position: absolute;
                        top: 0;
                        left: calc(50% - 0.4rem);
                        text-indent: 0;
                        margin: 0;
                        padding: 0;
                        font-family: fontawesome;
                        color: #446d92;
                    }

                    &:hover {
                        color: $color-denim;
                    }
                }

                &:first-child {
                    a {
                        margin-left: 0;
                    }

                    &::before {
                        content: "";
                        width: 54px;
                        height: 50px;
                        position: absolute;
                        // background-color: red;
                        z-index: 40;
                        left: 270px;
                        top: 75px;
                    }
                }

                > .sub-menu {
                    display: none;
                    width: 100%;
                    background-color: $color-denim;
                    height: 48px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 10;
                    justify-content: center;
                    padding-left: 280px;

                    @media #{$medium-only} {
                        justify-content: center;
                        padding: 0;
                    }

                    &:after {
                        content: "";
                        width: 50vw;
                        height: 48px;
                        position: absolute;
                        right: -50vw;
                        background-color: $color-denim;
                    }

                    li {
                        padding: 1rem;

                        a {
                            color: #fff;

                            &:hover {
                                color: $color-sunrise;
                            }
                        }
                    }
                }

                &:hover > .sub-menu {
                    display: flex;
                }
            }
        }
    }
}

body > header.mobile {
    $mobile-header-height: 46px; // Height of header
    $nav-toggle-size: 40px; // Size of hamburger
    $mobile-header-padding: #{($mobile-header-height - $nav-toggle-size) / 2};

    display: none;
    padding: $mobile-header-padding;
    width: 100%;
    height: 90px;
    background-color: #fff;

    @include with-touch-nav {
        display: flex;
        justify-content: space-between;
    }

    // The menu toggle button
    .toggle-off-canvas {
        display: inline-block;
        font-weight: 100;
        font-size: $nav-toggle-size;
        cursor: pointer;
        color: $color-denim;
        margin-top: 10px;

        &::before {
            font-family: FontAwesome;
            font-size: $nav-toggle-size;
            width: $nav-toggle-size;
            height: $nav-toggle-size;
            margin-right: 0.5em;
            vertical-align: top;
            content: "\f0c9";
        }
    }

    .logo {
        display: inline-block;

        img {
            width: 220px;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
            height: auto;
        }
    }
}

body > .mobile-off-canvas {
    position: fixed;
    top: 90px;
    z-index: 105;
    width: 60vw;
    min-width: 280px;
    max-width: 400px;
    height: 100vh;
    background-color: white;
    overflow: scroll;

    transition: left 0.5s ease-in-out;
    left: -100%;

    &.active {
        left: 0;
    }

    header {
        padding: 8px;
        margin-bottom: 16px;

        border-bottom: 1px solid black;
    }

    nav {
        padding: 0px;
        font-size: 24px;

        ul {
            margin: 0;
            padding: 0;
            border-top: 1px solid $color-midnight;

            li {
                display: block;
                padding: 0px;
                margin: 0px;
                border-bottom: 1px solid $color-midnight;

                &.menu-item-has-children {
                    border-bottom: none;
                }

                a {
                    color: $color-denim;
                    font-size: 1rem;
                    padding: 15px 0px 15px 10px;
                    display: inline-block;
                    width: 100%;
                }

                ul.sub-menu {
                    border-top: none;
                    border-bottom: 1px solid $color-midnight;
                    padding-bottom: 1rem;

                    li {
                        border-bottom: none;
                        padding: 0rem 1rem;

                        a {
                            padding: 0.5rem 0;
                            padding-left: 1rem;
                            text-indent: -1rem;

                            &:before {
                                content: "\f105";
                                font-family: FontAwesome;
                                color: $color-stone;
                                margin-right: 10px;
                                position: relative;
                            }
                        }
                    }
                }
            }
        }

        .social-links ul {
            border: none;

            li {
                border: none;
                display: inline-block;
                padding: 0 1rem 0 0;

                a {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
